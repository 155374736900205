import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { Router } from "@/helpers/ClaimsGate/routes/Router";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { FunnelsService, WorkspaceService } from "@claimsgate/core";
import { InfoModalService } from "@/components/shared/global/informationModal/InfoModalService";
import { PixelInitalisationService } from "@/helpers/ClaimsGate/events/PixelInitalisationService";
import { WorkspaceReferralService } from "@/helpers/ClaimsGate/referral/WorkspaceReferralService";
import { Funnel, Page, ReminderSequence, Workspace } from "@claimsgate/core-types";
import { ParserService } from "@/helpers/ClaimsGate/pages/parsers";
import { PageHelper } from "@/helpers/ClaimsGate/funnels/PageHelper";
import { BaseValidationService, PageService, PageValidationService } from "@/helpers/ClaimsGate/pages";
import { UserBlockComputeService } from "@/helpers/ClaimsGate/pages/computes";
import { ComputeProcessor, getComputeProcessor } from "@/helpers/ClaimsGate/computes/ComputeProcessor";
import { RepeatableService } from "@/helpers/ClaimsGate/pages/repeatable";
import { ConditionalProcessor, getConditionalProcessor } from "@/helpers/ClaimsGate/conditionals/ConditionalProcessor";
import { UserService } from "@/helpers/ClaimsGate/users/UserService";
import { getFirebaseBackend } from "@/authUtils";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
import { PixelsService } from "@/helpers/ClaimsGate/events/PixelsService";
import { EventsService } from "@/helpers/ClaimsGate/events/EventsService";
import { Events } from "@/helpers/ClaimsGate/events/Events";
import { AbstractUserBlockMethods } from "@/types/vue/AbstractUserBlockMethods";
import { FormState } from "./BlockFormInstance";

export type UserBlocks =
  | "BlockEmail"
  | "BlockPhone"
  | "BlockContactDetails"
  | "BlockConsent"
  | "BlockDateOfBirth"
  | "BlockCompany"
  | "BlockClaimAddress"
  | "BlockManualIdUpload";
export type BlockRender = {
  $refs: Record<UserBlocks, AbstractUserBlockMethods>;
};

/** Returns the default state */
export function getDefaultState(state: Vue) {
  return {
    pageId: state.$route.params.pageId,
    claimId: state.$route.params.claimId,
    loading: true,
    page: {} as Page,
    agreementId: null as string,
    funnelMeta: null as Funnel,
    reminderSequences: [] as Array<ReminderSequence>,
    user: null,
    userId: null as string,
    db: getFirebaseBackend().firestore(),
    baseValidationService: new BaseValidationService(),
    userBlockComputeService: new UserBlockComputeService(),
    computeProcessor: getComputeProcessor() as ComputeProcessor,
    conditionalProcessor: getConditionalProcessor() as ConditionalProcessor,
    repeatableService: new RepeatableService(),
    router: Router.createInstance(),
    userHelper: getUserHelper(),
    userService: new UserService(),
    workspaceReferralService: new WorkspaceReferralService(),
    workspaceService: new WorkspaceService(getFirebaseBackend().firebase()),
    workspace: {} as Workspace,
    variableService: new VariableService(),
    pixelsService: new PixelsService(),
    pageHelper: new PageHelper(),
    parserService: new ParserService(),
    pageService: new PageService(),
    pixelInitalisationService: new PixelInitalisationService(),
    pageValidationService: new PageValidationService(),
    events: new Events(),
    eventsService: new EventsService(),
    funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
    infoModalService: new InfoModalService(state.$infoModal),
    isWaitingForCatchHook: false,
    userDataService: undefined as UserDataService,
    claimDataService: undefined as ClaimDataService,
    computesInProgress: undefined as boolean,
    tryAutoSubmit: false,
    isProcessing: false,
    isError: false,
  };
}

import { navigationComputed, eventComputed, formComputed } from "@/state/helpers";
import { FormRefs, FormState } from "./BlockFormInstance";
import { BlockRender } from "./state";

export function mappedComputed() {
  return {
    ...(formComputed as Record<keyof typeof formComputed, any>),
    ...(eventComputed as Record<keyof typeof eventComputed, any>),
    ...(navigationComputed as Record<keyof typeof navigationComputed, any>),
  };
}

export const computed = {
  blockRefs,
  funnelId,
  funnelSlug,
};

function blockRefs(_state: FormState): Array<BlockRender> {
  const state = _state as FormState & FormRefs;
  return state.$refs?.rowRender?.$refs?.blockRender;
  //?.$refs?.rowRender[0]?.$refs?.blockRender;
}

/**
 * Split the human redable slug out of the funnelId route param
 */
function funnelId(state: FormState): string {
  if (state.$route.params?.funnelId?.includes("-")) {
    return state.$route.params.funnelId?.split("-").pop();
  } else {
    return state.$route.params?.funnelId;
  }
}

function funnelSlug(state: FormState): string {
  if (state.$route.params?.funnelId?.includes("-")) {
    const parts = state.$route.params?.funnelId?.split("-");
    return parts.slice(0, parts.length - 1).join("-");
  } else {
    return "";
  }
}

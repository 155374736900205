import { FormState } from "./BlockFormInstance";
import { Watchers } from "@/types";
import { next, stopProcessing, previous } from "./methods";
import { ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { createClaimId, getPageTotalIterations } from "./next";
import { onStagingEnvironment } from "@/helpers/ClaimsGate/RedirectHelper";

export const watchers: Watchers<FormState, "event"> = {
  event,
};

/** When the form has received an event */
async function event(state: FormState): Promise<void> {
  const { name, claimId, agreementId } = state.event as { name: string; claimId: string; agreementId: string };

  if (name === state.eventValues.next) {
    if (!(await next(state))) {
      stopProcessing(state);
    }
  } else if (name === state.eventValues.previous) {
    if (!(await previous(state))) {
      stopProcessing(state);
    }
  } else if (name === state.eventValues.refresh) {
    await refresh(state);
  } else if (name === state.eventValues.updateClaimId) {
    updateClaimId(state, claimId);
  } else if (name === state.eventValues.nextRepeatablePage) {
    await nextRepeatablePage(state);
  } else if (name === state.eventValues.previousRepeatablePage) {
    await previousRepeatablePage(state);
  } else if (name === state.eventValues.deleteCurrentRepeatablePage) {
    await deleteCurrentRepeatablePage(state);
  } else if (name === state.eventValues.newAgreement) {
    storeNewAgreementId(state, agreementId);
  } else if (name === state.eventValues.sendWelcomeMessage) {
    await sendWelcomeMessage(state);
  }
}

/** Stores the latest agreement in storage */
function storeNewAgreementId(state: FormState, agreementId) {
  state.agreementId = agreementId;
}

/** Refreshes the claim data and user data services */
async function refresh(state: FormState) {
  // Refresh the user data service if it exists
  if (state.userDataService) {
    await state.userDataService.refresh();
  }

  // Refresh the claim data service if it exists
  if (state.claimDataService) {
    await state.claimDataService.refresh();
  }
}

function updateClaimId(state: FormState, claimId: string) {
  state.claimId = claimId;

  if (!state.claimDataService) {
    state.claimDataService = ClaimDataService.getInstance(claimId);

    state.claimDataService.setArtefact("claimId", state.claimId);
  }
}

async function deleteCurrentRepeatablePage(state: FormState) {
  const loaderName = "deleteRepeatablePage";

  state.$store.dispatch("form/setLoader", { name: loaderName, value: true });

  console.log("Delete current iteration", JSON.stringify(state.page.currentIteration));

  await state.repeatableService.deleteIteration(state.claimId, state.page, state.page.currentIteration);

  // load in previous iteration
  state.page.currentIteration -= 1;

  state.repeatableService.loadIteration(state.claimId, state.page, state.page.currentIteration);

  state.page.totalIterations = getPageTotalIterations(state);

  await state.pageService.resetValidation(state.page);

  state.$store.dispatch("events/fire", {
    name: state.eventValues.refreshPage,
  });

  state.$store.dispatch("form/setLoader", { name: loaderName, value: false });
}

async function previousRepeatablePage(state: FormState) {
  const loaderName = "previousRepeatablePage";

  state.$store.dispatch("form/setLoader", { name: loaderName, value: true });

  const currentIteration = state.page.currentIteration;
  // Can't go back from 0
  if (currentIteration === 0) {
    state.$store.dispatch("form/setLoader", { name: loaderName, value: false });
    return true;
  }

  const result = await state.repeatableService.previousPage(state.claimId, state.page, currentIteration, state);

  if (!result) {
    //Validation failed, don't got to previous
    state.$store.dispatch("form/setLoader", { name: loaderName, value: false });

    return false;
  }

  state.$store.dispatch("events/fire", {
    name: state.eventValues.refreshPage,
  });

  state.page.currentIteration -= 1;

  state.page.totalIterations = getPageTotalIterations(state);

  state.$store.dispatch("form/setLoader", { name: loaderName, value: false });

  return true;
}

async function nextRepeatablePage(state: FormState, refresh = true) {
  const loaderName = "nextRepeatablePage";
  state.$store.dispatch("form/setLoader", { name: loaderName, value: true });

  if (!state.claimId) {
    state.claimId = createClaimId(state);
  }

  if (!state.claimDataService) {
    state.claimDataService = new ClaimDataService(state.userId, state.claimId);
    state.claimDataService.setArtefact("claimId", state.claimId);
  }

  if (state.page.currentIteration === undefined) {
    state.page.currentIteration = 0;
  }

  const result = await state.repeatableService.nextPage(state.page, state.claimId, state.$store, state, refresh);

  state.page.totalIterations = getPageTotalIterations(state);

  return result;
}

async function sendWelcomeMessage(state: FormState): Promise<boolean> {
  try {
    if (!state.funnelMeta?.welcomeMessage) {
      return false;
    }

    // Some data is missing to send a message
    if (!state.userId || !state.claimId || !state.funnelId || !state.page?.id) {
      return false;
    }

    const options = {
      userId: state.userId,
      claimId: state.claimId,
      funnelId: state.funnelId,
      pageId: state.page.id,
      useVariables: false,
      messageType: "funnelWelcome",
      messageId: state.funnelMeta.welcomeMessage.id,
    };
    const taskRef = state.db.collection("tasks").doc();

    const performAt = new Date();

    await taskRef.set({
      performAt: performAt,
      status: "scheduled",
      worker: "sendMessage",
      options: options,
      // Denormalise values from options so we can query the tasks collection
      messageType: "funnelWelcome",
      claimId: state.claimId,
      userId: state.userId,
    });

    console.log(">>> sendWelcomeMessage taskRef.id", taskRef.id);

    return true;
  } catch (exception) {
    console.log("Task save e", exception);
    return false;
  }
}

import { FormState } from "./BlockFormInstance";

export async function runBlockTValidations(state: FormState): Promise<boolean> {
  try {
    const blockRefs = [];
    const validations = [];

    if (state.page.isAutoSubmitPage) {
      return true;
    }

    state.blockRefs.forEach((block) => {
      if (block.$refs["BlockDataArray"]) {
        const BlockDataArray = block.$refs["BlockDataArray"];

        blockRefs.push(BlockDataArray);

        validations.push(BlockDataArray?.validations());
      }
      if (block.$refs["BlockCurrency"]) {
        const BlockCurrency = block.$refs["BlockCurrency"];

        blockRefs.push(BlockCurrency);

        validations.push(BlockCurrency?.validations());
      }

      if (block.$refs["BlockSimplePhone"]) {
        console.log("RUNNING VALIDATIONS FOR BlockSimplePhone");

        const BlockSimplePhone = block.$refs["BlockSimplePhone"];

        blockRefs.push(BlockSimplePhone);

        validations.push(BlockSimplePhone?.validations());
      }

      if (block.$refs["BlockSimpleEmail"]) {
        const BlockSimpleEmail = block.$refs["BlockSimpleEmail"];

        blockRefs.push(BlockSimpleEmail);

        validations.push(BlockSimpleEmail?.validations());
      }

      if (block.$refs["BlockHTML"]) {
        const BlockHTML = block.$refs["BlockHTML"];

        blockRefs.push(BlockHTML);

        validations.push(BlockHTML?.validations());
      }

      if (block.$refs["BlockManualIdUpload"]) {
        const BlockManualIdUpload = block.$refs["BlockManualIdUpload"];

        blockRefs.push(BlockManualIdUpload);

        validations.push(BlockManualIdUpload?.validations());
      }

      if (block.$refs["BlockComplexQuestion"]) {
        const BlockComplexQuestion = block.$refs["BlockComplexQuestion"];

        blockRefs.push(BlockComplexQuestion);

        validations.push(BlockComplexQuestion?.validations());
      }

      // BlockNestedMultiSelect
      if (block.$refs["BlockNestedMultiSelect"]) {
        const BlockNestedMultiSelect = block.$refs["BlockNestedMultiSelect"];

        blockRefs.push(BlockNestedMultiSelect);

        validations.push(BlockNestedMultiSelect?.validations());
      }
    });
    const results = await Promise.all(validations);

    if (results.some((validation) => validation !== true)) return false;
    // run computes on all blocks
    const computes = blockRefs.map(async (block) => {
      if (!block.computes) return true;

      const answer = await block.computes();

      if (answer === false) return false;

      if (block?.storeAs?.id) {
        state.claimDataService.setArtefact(block.storeAs.id, answer);
      }
      return true;
    });

    const computeResults = await Promise.all(computes);

    if (computeResults.some((compute) => compute !== true)) return false;

    return true;
  } catch (exception) {
    console.error("runBlockValidations threw", exception);
    return false;
  }
}

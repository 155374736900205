// Block Component Registry - for lazy loading components
import { VueConstructor } from "vue";
import { Block, Page } from "@claimsgate/core-types";

// Type definitions
interface ComponentCache {
  [key: string]: VueConstructor;
}

// Component cache
const componentCache: ComponentCache = {};

// Component props cache
const componentPropsCache = {};
// Define import functions for each component
const importFunctions: Record<string, () => Promise<any>> = {
  // Basic blocks
  BlockButton: () => import("@/components/shared/blocks/button.vue"),
  BlockText: () => import("@/components/shared/blocks/text/text.vue"),
  BlockTitle: () => import("@/components/shared/blocks/text/title.vue"),
  BlockSubTitle: () => import("@/components/shared/blocks/text/subtitle.vue"),
  BlockParagraph: () => import("@/components/shared/blocks/paragraph.vue"),
  BlockImage: () => import("@/components/shared/blocks/image.vue"),
  BlockVideo: () => import("@/components/shared/blocks/video.vue"),
  BlockHTML: () => import("@/components/shared/blocks/html.vue"),
  BlockLink: () => import("@/components/shared/blocks/link.vue"),

  // Input blocks
  BlockInput: () => import("@/components/shared/blocks/input.vue"),
  BlockDropdown: () => import("@/components/shared/blocks/dropdown.vue"),
  BlockCheckbox: () => import("@/components/shared/blocks/checkbox.vue"),
  BlockSingleSelect: () => import("@/components/shared/blocks/singleselect.vue"),
  BlockMultiSelect: () => import("@/components/shared/blocks/multiselect.vue"),
  BlockDatepicker: () => import("@/components/shared/blocks/datepicker.vue"),
  BlockTextArea: () => import("@/components/shared/blocks/text/textarea.vue"),
  BlockNumber: () => import("@/components/shared/blocks/number.vue"),
  BlockDropdownSearch: () => import("@/components/shared/blocks/dropdownSearchV3/dropdownSearchV3.vue"),

  // File blocks
  BlockFileInput: () => import("@/components/shared/blocks/fileinput.vue"),
  BlockMultiFileInput: () => import("@/components/shared/blocks/multiFileInput.vue"),
  BlockDisplayFile: () => import("@/components/shared/blocks/displayFile/displayFile.vue"),

  // User blocks
  BlockEmail: () => import("@/components/shared/blocks/user/email/email.vue"),
  BlockPhone: () => import("@/components/shared/blocks/user/phone/phone.vue"),
  BlockUserAddress: () => import("@/components/shared/blocks/user/address/address.vue"),
  BlockContactDetails: () => import("@/components/shared/blocks/user/contactDetails/contactDetails.vue"),
  BlockDateOfBirth: () => import("@/components/shared/blocks/user/dateofbirth.vue"),
  BlockName: () => import("@/components/shared/blocks/user/name.vue"),
  BlockConsent: () => import("@/components/shared/blocks/user/consent/consent.vue"),
  BlockAgreement: () => import("@/components/shared/blocks/user/agreement.vue"),
  BlockNationalInsurance: () => import("@/components/shared/blocks/user/nationalinsurance.vue"),

  // Navigation blocks
  BlockPageButtons: () => import("@/components/shared/blocks/pagebuttons.vue"),
  BlockCarousel: () => import("@/components/shared/blocks/carousel.vue"),

  // Special blocks
  BlockCurrency: () => import("@/components/shared/blocks/currency/currency.vue"),
  BlockCountry: () => import("@/components/shared/blocks/country/country.vue"),
  BlockProgressBar: () => import("@/components/shared/blocks/progress.vue"),
  BlockDynamicProgressBar: () => import("@/components/shared/blocks/dynamicProgressBar.vue"),
  BlockSectionsProgressBar: () => import("@/components/shared/blocks/sectionsProgressBar/sectionsProgressBar.vue"),

  BlockSlaterGordonPcpLandingPage: () =>
    import("@/components/shared/blocks/slaterGordonPcpLandingPage/slaterGordonPcpLandingPage.vue"),

  BlockComplexLawPcpLandingPage: () =>
    import("@/components/shared/blocks/complexLawPcpLandingPage/complexLawPcpLandingPage.vue"),

  // Additional Components
  BlockKyb: () => import("@/components/shared/blocks/kyb/kyb.vue"),
  BlockPadding: () => import("@/components/shared/blocks/padding.vue"),
  BlockCompany: () => import("@/components/shared/blocks/company/company.vue"),
  BlockClaimAddress: () => import("@/components/shared/blocks/claimAddress/claimAddress.vue"),
  BlockVehicle: () => import("@/components/shared/blocks/utility/vehicle.vue"),
  BlockAltVehicle: () => import("@/components/shared/blocks/altVehicleBlock/altVehicleBlock.vue"),
  BlockMotorSpecs: () => import("@/components/shared/blocks/motorSpecs/motorSpecs.vue"),
  BlockMultiMotorSpecs: () => import("@/components/shared/blocks/multiMotorSpecs/multiMotorSpecs.vue"),
  BlockClaimsGateVehicle: () => import("@/components/shared/blocks/claimsGateVehicle/claimsGateVehicle.vue"),
  BlockDataArray: () => import("@/components/shared/blocks/dataArray/dataArray.vue"),
  BlockYoti: () => import("@/components/shared/blocks/utility/yoti/yoti.vue"),
  BlockWorkspace: () => import("@/components/shared/blocks/workspaces/workspace.vue"),
  BlockTransferWorkspace: () => import("@/components/shared/blocks/transferWorkspace/transferWorkspace.vue"),
  BlockCopyClaim: () => import("@/components/shared/blocks/copyClaim/copyClaim.vue"),
  BlockTransferFunnel: () => import("@/components/shared/blocks/transferFunnel/transferFunnel.vue"),
  BlockCreditCheck: () => import("@/components/shared/blocks/checkboardCreditCheck/creditCheck.vue"),
  BlockOverdraftReport: () => import("@/components/shared/blocks/armalytix/overdraftReport.vue"),
  BlockSimplePhone: () => import("@/components/shared/blocks/simplePhone.vue"),
  BlockSimpleEmail: () => import("@/components/shared/blocks/simpleEmail.vue"),
  BlockPlaces: () => import("@/components/claimant/form/places/places.vue"),
  BlockDraw: () => import("@/components/shared/blocks/draw.vue"),
  BlockKyc: () => import("@/components/shared/blocks/user/kyc/kyc.vue"),
  BlockDsar: () => import("@/components/shared/blocks/dsar/dsar.vue"),
  BlockFaq: () => import("@/components/shared/blocks/faq/faq.vue"),
  BlockFinanceAgreement: () => import("@/components/shared/blocks/financeAgreement/financeAgreement.vue"),
  BlockManualIdUpload: () => import("@/components/shared/blocks/manualIdUpload/manualIdUpload.vue"),
  BlockComplexQuestion: () => import("@/components/shared/blocks/complexQuestion/complexQuestion.vue"),
  BlockNestedMultiSelect: () => import("@/components/shared/blocks/nestedMultiSelect/nestedMultiSelect.vue"),
  BlockManualVehicle: () => import("@/components/shared/blocks/manualVehicle/manualVehicle.vue"),
  BlockInfoCard: () => import("@/components/shared/blocks/user/infocard.vue"),
  BlockContainer: () => import("@/components/shared/blocks/row.vue"),
};

/**
 * Dynamically loads a block component by type
 * @param blockType The type of block to load
 * @returns Promise resolving to the component constructor
 */
export const getBlockComponent = async (blockType: string): Promise<VueConstructor | null> => {
  if (componentCache[blockType]) {
    return componentCache[blockType];
  }

  try {
    if (!importFunctions[blockType]) {
      console.warn(`Unknown block type: ${blockType}`);
      return null;
    }

    const componentModule = await importFunctions[blockType]();
    componentCache[blockType] = componentModule.default as VueConstructor;
    return componentModule.default;
  } catch (error) {
    console.error(`Error loading component ${blockType}:`, error);
    return null;
  }
};

/**
 * Preloads all block components for the builder
 * @returns Promise resolving to the component cache
 */
export const preloadAllBlockComponents = async (): Promise<boolean> => {
  const promises = Object.keys(importFunctions).map(async (blockType) => {
    try {
      const component = await getBlockComponent(blockType);

      if (component) {
        const vueComponent = component as any;

        // Try multiple locations for props
        const props = vueComponent.options?.props || vueComponent.props || vueComponent.extendOptions?.props || {};

        componentPropsCache[blockType] = props;

        // Debug log to see what's being found
        console.log(`Extracted props for ${blockType}:`, props);
      }
    } catch (error) {
      console.error(`Error preloading ${blockType}:`, error);
    }
  });

  await Promise.all(promises);
  return true;
};

/**
 * Recursively collects all block types from a block structure
 * @param blocks Array of blocks to collect types from
 * @param typeSet Set to collect types into
 */
const collectBlockTypes = (blocks: Block[] | undefined, typeSet: Set<string>): void => {
  if (!blocks) return;

  blocks.forEach((block) => {
    if (block.type) typeSet.add(block.type);

    // Handle nested structures
    if (block.blocks) collectBlockTypes(block.blocks, typeSet);
    if (block.rows) {
      block.rows.forEach((row) => {
        if (row.cols) {
          row.cols.forEach((col) => {
            if (col.blocks) collectBlockTypes(col.blocks, typeSet);
          });
        }
      });
    }
  });
};

/**
 * Preloads components needed for a specific set of blocks
 * @param blocks Array of blocks to preload components for
 */
export const preloadFormComponents = async (blocks: Block[]): Promise<void> => {
  if (!blocks || !blocks.length) return;

  const blockTypes = new Set<string>();
  collectBlockTypes(blocks, blockTypes);

  // Preload all required components in parallel
  await Promise.all(Array.from(blockTypes).map((type) => getBlockComponent(type)));
};

/**
 * Gets component props for the builder
 * @param blockType The type of block to get props for
 * @returns The component's prop definitions
 */
export const getComponentProps = async (blockType: string): Promise<any> => {
  // First make sure the component is loaded
  const component = await getBlockComponent(blockType);
  console.log("{} COMPONENT PROPS", component);

  if (!component) {
    return {};
  }

  // Use type assertion to avoid TypeScript errors
  const vueComponent = component as any;

  // Try to access props from different possible locations
  const props = vueComponent.options?.props || vueComponent.props || {};

  return props;
};

export function getComponentPropsSync(blockType) {
  if (componentPropsCache[blockType]) {
    return componentPropsCache[blockType];
  }

  // If not in cache but component is loaded, try to extract props
  if (componentCache[blockType]) {
    const component = componentCache[blockType] as any;

    // Try different locations for props
    const props =
      component.options?.props || // Options API
      component.props || // Direct props
      component.extendOptions?.props || // Vue.extend()
      {};

    // Cache the extracted props
    componentPropsCache[blockType] = props;
    return props;
  }

  console.warn(`Props for ${blockType} not found in cache. Did you forget to preload it?`);
  return {};
}

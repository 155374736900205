<script lang="ts">
// Import component configuration
import Layout from "@/components/shared/layout/main.vue";

import Vue from "vue";
import { hooks } from "./hooks";

import { BlockRender, getDefaultState } from "./state";
import { watchers } from "./watchers";
import { computed, mappedComputed } from "./computed";
import RowRender from "@/components/shared/formRender/rowRender/rowRender.vue";
import PageRender from "@/components/shared/formRender/pageRender/pageRender.vue";

// Component definition
export default Vue.extend({
  components: {
    Layout,
    RowRender,
    PageRender,
  },
  data() {
    return {
      ...getDefaultState(this),
    };
  },
  async beforeMount(): Promise<void> {
    await hooks.mounted(this);
  },

  async destroyed(): Promise<void> {
    await hooks.destroyed(this);
  },

  computed: {
    ...mappedComputed(),
    blockRefs(): Array<BlockRender> {
      return computed.blockRefs(this);
    },
    funnelId(): string {
      return computed.funnelId(this);
    },
    funnelSlug(): string {
      return computed.funnelSlug(this);
    },
  },
  watch: {
    event: async function () {
      watchers.event(this);
    },
  },
});
</script>

<template>
  <Layout :loading="loading" :fullWidth="page?.isFullWidth">
    <template v-slot:blocks>
      <!-- dont remove this v-if..-->
      <template v-if="!loading">
        <template v-if="page">
          <PageRender :page="page">
            <RowRender
              v-bind:blocks.sync="page.blocks"
              :page="page"
              :claimId="claimId"
              ref="rowRender"
              :builder="false"
              :parentId="undefined"
            ></RowRender>
          </PageRender>
          <!--<PageRender v-bind:blocks.sync="page.blocks" :page="page" :claimId="claimId" ref="pageRender" />-->
        </template>
      </template>
    </template>
  </Layout>
</template>
